import { forwardRef, useContext } from "react";
import { ButtonPlus } from "../../components/ButtonPlus/ButtonPlus";
import "./ressources.scss";
import { StoreContext } from "../../store/store";

export const Ressources = forwardRef((props, ref) => {
  const { section, bgColor, template } = props;
  const { ListCategoryInvest, title } = section;
  const { lang } = useContext(StoreContext);

  return (
    <section className="ressources-section">
      <span className="ressources-title">{title.data}</span>

      <div className="ressources-container">
        {ListCategoryInvest.data.map((ressource, index) => (
          <div key={index} className="ressource-content">
            <span key={index} className="ressource-content-label">
              {ressource[lang].title.data}
            </span>
            <ButtonPlus to="" color="dark" />
          </div>
        ))}
      </div>
    </section>
  );
});
