import { forwardRef } from "react";
import "./text.scss";

export const Text = forwardRef((props, ref) => {
  const { section, bgColor, template } = props;
  const { contenu, contenu2, title } = section;

  return (
    <section className="text-section">
      <div className="text-section-content">
        <span className="text-section-title">{title.data}</span>
        <div
          className="text-section-subtitle"
          dangerouslySetInnerHTML={{
            __html: contenu.data,
          }}
        />
        <div
          className="text-section-description"
          dangerouslySetInnerHTML={{
            __html: contenu2.data,
          }}
        />
      </div>
    </section>
  );
});
