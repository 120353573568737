import { useState, createContext } from "react";

export const StoreContext = createContext();

export const StoreProvider = ({ children }) => {
  const [lang, setLang] = useState(
    localStorage.getItem("currentLanguage")
      ? localStorage.getItem("currentLanguage")
      : navigator.language.toLocaleLowerCase().slice(0, 2) === "fr"
      ? "fr"
      : "en"
  );
  const [user, setUser] = useState();
  const [pageData, setPageData] = useState();
  const [menusData, setMenusData] = useState();
  const [sectionsDOM, setSectionsDOM] = useState([]);
  const [headerDOM, setHeaderDOM] = useState();
  const [filtersData, setFiltersData] = useState({});

  return (
    <StoreContext.Provider
      value={{
        lang,
        setLang,
        user,
        setUser,
        pageData,
        setPageData,
        sectionsDOM,
        setSectionsDOM,
        headerDOM,
        setHeaderDOM,
        filtersData,
        setFiltersData,
        menusData,
        setMenusData,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};
