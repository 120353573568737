import { forwardRef, useContext, useEffect, useRef, useState } from "react";
import "./pageList.scss";
import { ButtonPlus } from "../../components/ButtonPlus/ButtonPlus";
import { StoreContext } from "../../store/store";

export const PageList = forwardRef((props, ref) => {
  const { section, template, bgColor } = props;
  const { category, title } = section;
  const { lang } = useContext(StoreContext);

  const [isHome, setIsHome] = useState(false);

  useEffect(() => {
    const checkIfHome = () => {
      const path = window.location.pathname;
      return (
        path === "/" ||
        path === "/en" ||
        path === "/fr" ||
        path === "/en/" ||
        path === "/fr/"
      );
    };
    setIsHome(checkIfHome());
  }, []);

  const List = (props) => {
    const { list } = props;

    const scrollRef = useRef(null);
    const containerRef = useRef(null);
    const [isMobile, setIsMobile] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
      const checkIfMobile = () => {
        setIsMobile(window.innerWidth <= 960);
      };

      checkIfMobile();
      window.addEventListener("resize", checkIfMobile);

      return () => window.removeEventListener("resize", checkIfMobile);
    }, []);

    useEffect(() => {
      const scrollContainer = scrollRef.current;
      if (!scrollContainer || isMobile) return;

      let isMouseDown = false;
      let startX;
      let scrollLeft;

      const handleMouseDown = (e) => {
        isMouseDown = true;
        startX = e.pageX - scrollContainer.offsetLeft;
        scrollLeft = scrollContainer.scrollLeft;
      };

      const handleMouseLeave = () => {
        isMouseDown = false;
      };

      const handleMouseUp = () => {
        isMouseDown = false;
      };

      const handleMouseMove = (e) => {
        if (!isMouseDown) return;
        e.preventDefault();
        const x = e.pageX - scrollContainer.offsetLeft;
        const walk = (x - startX) * 2;
        scrollContainer.scrollLeft = scrollLeft - walk;
      };

      scrollContainer.addEventListener("mousedown", handleMouseDown);
      scrollContainer.addEventListener("mouseleave", handleMouseLeave);
      scrollContainer.addEventListener("mouseup", handleMouseUp);
      scrollContainer.addEventListener("mousemove", handleMouseMove);

      return () => {
        scrollContainer.removeEventListener("mousedown", handleMouseDown);
        scrollContainer.removeEventListener("mouseleave", handleMouseLeave);
        scrollContainer.removeEventListener("mouseup", handleMouseUp);
        scrollContainer.removeEventListener("mousemove", handleMouseMove);
      };
    }, [isMobile]);

    const handlePrev = () => {
      setCurrentIndex((prevIndex) =>
        prevIndex === 0 ? list.length - 1 : prevIndex - 1
      );
    };

    const handleNext = () => {
      setCurrentIndex((prevIndex) =>
        prevIndex === list.length - 1 ? 0 : prevIndex + 1
      );
    };

    // useEffect(() => {
    //   if (scrollRef.current) {
    //     const cardWidth = scrollRef.current.offsetWidth;
    //     scrollRef.current.scrollTo({
    //       left: currentIndex * cardWidth,
    //       behavior: "smooth",
    //     });
    //   }
    // }, [currentIndex]);

    useEffect(() => {
      if (containerRef.current && isMobile) {
        containerRef.current.style.transform = `translateX(-${
          currentIndex * 100
        }%)`;
      }
    }, [currentIndex, isMobile]);

    return (
      <div className="page-list-content-activities" ref={scrollRef}>
        <div
          className="page-list-content-activities-container"
          ref={containerRef}
        >
          {list.map((item, index) => (
            <div key={index} className="page-list-content-activitie-item">
              <div
                className="page-list-content-scroll-arrow left"
                onClick={handlePrev}
              >
                <img src="/icon/chevron-left.svg" alt="chevron-left" />
              </div>
              <Card {...item} />
              <div
                className="page-list-content-scroll-arrow right"
                onClick={handleNext}
              >
                <img src="/icon/chevron-right.svg" alt="chevron-right" />
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const Card = (props) => {
    const { title, button, media } = props[lang];

    return (
      <div className="page-list-content-activities-card">
        <img
          src={media.data.path}
          alt={media.data.alt}
          className="page-list-content-activities-card-image"
        />
        <ButtonPlus to={button.url.data} />
        <span className="page-list-content-activities-card-title">
          {title.data}
        </span>
      </div>
    );
  };

  return (
    <section className={`page-list-section ${isHome ? "home" : ""}`}>
      {category.data.map((page, index) => (
        <div
          className={`page-list-content ${index !== 0 || isHome ? "home" : ""}`}
          key={index}
        >
          <span
            className={`page-list-title ${index !== 0 || isHome ? "home" : ""}`}
          >
            {page[lang].title.data}
          </span>
          {index !== 0 || isHome ? (
            <>
              <p className="page-list-description">{page[lang].content.data}</p>
              <ButtonPlus to={page[lang].button.url.data} />
            </>
          ) : (
            <List list={page[lang].sousCategorie.data} />
          )}
        </div>
      ))}
    </section>
  );
});
