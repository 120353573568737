import { forwardRef } from "react";
import "./textWithAction.scss";
import { ButtonPlus } from "../../components/ButtonPlus/ButtonPlus";

export const TextWithAction = forwardRef((props, ref) => {
  const { section, template, bgColor } = props;
  const { content, title } = section;

  return (
    <section className="text-with-action-section">
      <span className="text-with-action-title">{title.data}</span>
      <div
        className="text-with-action-description"
        dangerouslySetInnerHTML={{ __html: content.data }}
      />
      <ButtonPlus to="#" />
    </section>
  );
});
