import { forwardRef, useContext, useEffect, useRef, useState } from "react";
import "./mozaic.scss";
import { motion, useAnimation } from "framer-motion";
import { StoreContext } from "../../store/store";

export const Mozaic = forwardRef((props, ref) => {
  const { section, bgColor, template } = props;
  const { listNews, title } = section;
  const { lang } = useContext(StoreContext);

  const controls = useAnimation();
  const sectionRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      {
        threshold: 0.4,
      }
    );

    const currentRef = sectionRef.current;
    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);

  useEffect(() => {
    if (isVisible) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [controls, isVisible]);

  const itemVariants = {
    hidden: (index) => ({
      opacity: 0,
      x: index % 2 === 0 ? "-10%" : "10%",
    }),
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 1.5,
        ease: "easeInOut",
      },
    },
  };

  return (
    <section className="mozaic-section" ref={sectionRef}>
      <div className="mozaic-container">
        {section.listNews.map((news, index) => {
          const { content, media, shortContent, subTitle, title } = news[lang];

          return (
            <motion.div
              key={index}
              className="mozaic-content-container"
              custom={index}
              variants={itemVariants}
              initial="hidden"
              animate={controls}
              transition={{ delay: index * 0.3 }}
            >
              <div className="mozaic-content">
                <span className="mozaic-content-title">{title.data}</span>

                <p className="mozaic-content-description">
                  {shortContent.data}
                </p>

                <div className="mozaic-content-companies-container">
                  {/* {news.companies.map((company, index) => (
                    <img
                      src={company.src}
                      alt={company.alt}
                      key={index}
                      className="mozaic-content-companies-container-logo"
                      style={{ width: company.width, height: company.height }}
                    />
                  ))} */}
                </div>
              </div>

              <img
                src={media.data.path}
                alt={media.data.alt}
                className={`mozaic-content-image ${
                  index % 2 === 0 ? "order-last" : "order-first"
                }`}
              />
            </motion.div>
          );
        })}
      </div>
    </section>
  );
});
