import { forwardRef, useContext, useEffect, useRef, useState } from "react";
import "./worldMap.scss";
import { StoreContext } from "../../store/store";

const contentWorldMap = {
  label: "We are a collective of 2,500 creatives around the world",
  maps: [
    {
      src: "/img/AMERICA.png",
      alt: "North America",
      name: "America",
    },
    {
      src: "/img/EUROPE.png",
      alt: "Europe",
      name: "Europe",
    },
    {
      src: "/img/AFRICA.png",
      alt: "Africa",
      name: "Africa",
    },
  ],
};

export const WorldMap = forwardRef((props, ref) => {
  const { section, template, bgColor } = props;
  const { listCity, title } = section;
  const { lang } = useContext(StoreContext);

  const [hoveredCity, setHoveredCity] = useState(null);
  const [currentMapIndex, setCurrentMapIndex] = useState(() => {
    return (
      contentWorldMap.maps.findIndex(
        (map) => map.name.toLowerCase() === "europe"
      ) || 0
    );
  });
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const containerRef = useRef(null);

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartX(e.pageX - containerRef.current.offsetLeft);
    setScrollLeft(containerRef.current.scrollLeft);
  };

  const handleMouseLeave = () => {
    setIsDragging(false);
    snapToClosestMap();
  };

  const handleMouseUp = () => {
    setIsDragging(false);
    snapToClosestMap();
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - containerRef.current.offsetLeft;
    const walk = (x - startX) * 2;
    containerRef.current.scrollLeft = scrollLeft - walk;
  };

  const handleTouchStart = (e) => {
    setIsDragging(true);
    setStartX(e.touches[0].pageX - containerRef.current.offsetLeft);
    setScrollLeft(containerRef.current.scrollLeft);
  };

  const handleTouchMove = (e) => {
    if (!isDragging) return;
    const x = e.touches[0].pageX - containerRef.current.offsetLeft;
    const walk = (x - startX) * 2;
    containerRef.current.scrollLeft = scrollLeft - walk;
  };

  const snapToClosestMap = () => {
    if (!containerRef.current) return;
    const containerWidth = containerRef.current.offsetWidth;
    const scrollPosition = containerRef.current.scrollLeft;
    const mapIndex = Math.round(scrollPosition / containerWidth);
    setCurrentMapIndex(mapIndex);
    containerRef.current.scrollTo({
      left: mapIndex * containerWidth,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTo({
        left: currentMapIndex * containerRef.current.offsetWidth,
        behavior: "auto",
      });
    }
  }, [currentMapIndex]);

  return (
    <section className="world-map-section">
      <span className="world-map-label">{title.data}</span>

      <div
        ref={containerRef}
        onMouseDown={handleMouseDown}
        onMouseLeave={handleMouseLeave}
        onMouseUp={handleMouseUp}
        onMouseMove={handleMouseMove}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleMouseUp}
        className="world-map-content-container"
      >
        {contentWorldMap.maps.map((map, index) => (
          <div className="world-map-content" key={index}>
            <img
              src={map.src}
              alt={map.alt}
              key={index}
              className="world-map-content-image"
            />
            {listCity.data
              .filter((city) => city[lang].continent.data === map.name)
              .map((city, cityIndex) => (
                <div
                  key={cityIndex}
                  className={`${
                    city[lang].city.data === "Paris"
                      ? "world-map-content-point-large"
                      : "world-map-content-point"
                  }`}
                  style={{
                    left: city[lang].positionLeft.data,
                    top: city[lang].positionTop.data,
                  }}
                  onMouseEnter={() =>
                    setHoveredCity({
                      city: city[lang].city.data,
                      continent: city[lang].continent.data,
                      country: city[lang].country.data,
                    })
                  }
                  onMouseLeave={() => setHoveredCity(null)}
                ></div>
              ))}
          </div>
        ))}

        {hoveredCity && hoveredCity.city === "Paris" && <Paris />}
      </div>

      <div className="world-map-name-container">
        {contentWorldMap.maps.map((map, index) => (
          <div
            key={index}
            className={`world-map-name-content ${
              index === currentMapIndex ? "active" : ""
            }`}
          >
            {hoveredCity && hoveredCity.city !== "Paris" && (
              <div className="world-map-name-content-wrapper">
                <span className="world-map-name-content-wrapper-country">
                  {hoveredCity && hoveredCity.continent === map.name
                    ? hoveredCity.country
                    : ""}
                </span>
                <span className="world-map-name-content-wrapper-city">
                  {hoveredCity && hoveredCity.continent === map.name
                    ? hoveredCity.city
                    : ""}
                </span>
              </div>
            )}

            <span className="world-map-name-content-name">{map.alt}</span>
          </div>
        ))}
      </div>

      <div className="world-map-bullets">
        {contentWorldMap.maps.map((_, index) => (
          <div
            key={index}
            className={`world-map-bullet ${
              index === currentMapIndex ? "active" : ""
            }`}
            onClick={() => setCurrentMapIndex(index)}
          />
        ))}
      </div>
    </section>
  );
});

const parisContent = {
  title: "Paris",
  subTitle: "Mediawan group headquarters",
  description:
    "Leading independent European audiovisual content studio over 80 independent production companies, an international distribution arm and 22 streaming channels and services",
};

const Paris = () => {
  return (
    <div className="paris-container">
      <span className="paris-container-title">{parisContent.title}</span>
      <span className="paris-container-sub-title">{parisContent.subTitle}</span>
      <p className="paris-container-description">{parisContent.description}</p>
    </div>
  );
};
