import { useContext } from "react";
import "./buttonPlus.scss";
import { Link } from "react-router-dom";
import { StoreContext } from "../../store/store";

export const ButtonPlus = (props) => {
  const { to, color } = props;
  const { lang } = useContext(StoreContext);

  const colorClass = color === "dark" ? "dark" : "light";

  return (
    <Link to={`/${lang}/${to}`} className="button-plus-component-container">
      <div className={`button-plus-component ${colorClass}`}>
        <div className={`button-plus-horizontal ${colorClass}`}></div>
        <div className={`button-plus-vertical ${colorClass}`}></div>
      </div>
    </Link>
  );
};
