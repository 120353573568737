import { forwardRef, useContext } from "react";
import "./picture.scss";
import { StoreContext } from "../../store/store";

export const Picture = forwardRef((props, ref) => {
  const { section, template, bgColor } = props;
  const { listSliderProduction, title } = section;
  const { lang } = useContext(StoreContext);

  const content = listSliderProduction.data[0][lang] ?? {};

  return (
    content && (
      <section className="picture-section">
        <div className="picture-container">
          <img
            src={content.media.data.path}
            alt={content.media.data.alt}
            className="picture-content-image"
          />

          {content.title.data && (
            <span className="picture-text picture-text-left">
              {content.title.data}
            </span>
          )}

          {content.from.data && (
            <span className="picture-text picture-text-right">
              {content.from.data}
            </span>
          )}
        </div>
      </section>
    )
  );
});
