import { forwardRef, useEffect, useState } from "react";
import "./hero.scss";

export const Hero = forwardRef((props, ref) => {
  const { section, template, bgColor } = props;
  const { content, media, title } = section;

  // imageFirst true if location is /join-us
  const [imageFirst, setImageFirst] = useState(false);

  useEffect(() => {
    setImageFirst(window.location.pathname.endsWith("/join-us"));
  }, []);

  const orderClass = imageFirst ? "order-first" : "order-last";

  return (
    <section className="hero-section">
      <div className="hero-content">
        <span className="hero-title">{title.data}</span>
        <div
          className="hero-description"
          dangerouslySetInnerHTML={{ __html: content.data }}
        />
      </div>

      <img
        src={media.data?.path}
        alt={media.data?.alt}
        className={`hero-image ${orderClass}`}
      />
    </section>
  );
});
