import "./buttonLeft.scss";
import { Link } from "react-router-dom";

export const ButtonLeft = (props) => {
  const { to, color, onClick } = props;

  const colorClass = color === "dark" ? "dark" : "";

  return (
    <Link to={to}>
      <div className={`button-left-component ${colorClass}`}>
        <img
          src={`/icon/v-letter${colorClass ? `-${colorClass}` : ""}.svg`}
          alt="left"
          onClick={onClick}
          className="button-left-icon"
        />
      </div>
    </Link>
  );
};
