import { forwardRef, useContext, useEffect, useState } from "react";
import "./carousel.scss";
import { StoreContext } from "../../store/store";

export const Carousel = forwardRef((props, ref) => {
  const { section, bgColor, template } = props;
  const { listLogo, title } = section;
  const { lang } = useContext(StoreContext);

  const firstRow = listLogo.data.slice(0, 10);
  const secondRow = listLogo.data.slice(10, 20);
  const thirdRow = listLogo.data.slice(20, 30);

  const list =
    listLogo.length > 10 ? [firstRow, secondRow, thirdRow] : [firstRow];

  const renderCompanies = (companies) => (
    <>
      {[...companies, ...companies, ...companies, ...companies].map(
        (company, index) => (
          <img
            key={index}
            src={company[lang].media.data.path}
            alt={company[lang].title.data}
          />
        )
      )}
    </>
  );

  return (
    <section className="carousel-section">
      {title.data && <span className="carousel-title">{title.data}</span>}

      <div className="carousel-content">
        {list.map((companies, index) => (
          <div className="carousel-content-row" key={index}>
            <div className="carousel-content-inner">
              {renderCompanies(companies)}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
});
