import { forwardRef, useRef } from "react";
import "./article.scss";
import { ButtonDown } from "../../components/ButtonDown/ButtonDown";
import { ButtonLeft } from "../../components/ButtonLeft/ButtonLeft";

export const Article = forwardRef((props, ref) => {
  const { section } = props;
  const { btnUrl, content, media, subTitle, title } = section;

  const paragraphRef = useRef(null);

  const handleClick = () => {
    window.scrollTo({
      top: paragraphRef.current.offsetTop - 130.81, // 137 is the height of the header, 24 is the height of the buttonDown (130.81 with 13px of fontSize)
      left: 0,
      behavior: "smooth",
    });
  };

  const goBack = () => {
    window.history.back();
  };

  return (
    <section className="article-section">
      <div className="article-content">
        <div className="article-image-container">
          <img
            src={media.data.path}
            alt={media.data.alt}
            className="article-image"
          />
        </div>

        <div className="article-content-upper">
          <span className="article-pretitle">{subTitle.data}</span>
          <span className="article-title">{title.data}</span>
          <ButtonDown color="dark" onClick={handleClick} />
        </div>

        <div className="article-content-lower" ref={paragraphRef}>
          <p
            className="article-description"
            dangerouslySetInnerHTML={{
              __html: content.data,
            }}
          ></p>
        </div>

        <ButtonLeft color="dark" onClick={goBack} />
      </div>
    </section>
  );
});
