import { forwardRef } from "react";
import "./ourStory.scss";
import { ButtonPlus } from "../../components/ButtonPlus/ButtonPlus";

export const OurStory = forwardRef((props, ref) => {
  const { section, template, bgColor } = props;
  const { content, title, button } = section;

  return (
    <section className="our-story-section">
      <span className="our-story-label">{title.data}</span>

      <div className="our-story-description-container">
        <div
          className="our-story-description-content"
          dangerouslySetInnerHTML={{ __html: content.data }}
        ></div>
      </div>

      <ButtonPlus to={button.url.data} />
    </section>
  );
});
