import { forwardRef, useContext } from "react";
import "./dataTable.scss";
import { ItemTable } from "../../components/ItemTable/ItemTable";
import { StoreContext } from "../../store/store";

export const DataTable = forwardRef((props, ref) => {
  const { section, bgColor, template } = props;
  const { numberItem } = section;
  const { lang } = useContext(StoreContext);

  return (
    <section className="data-table-section">
      <div className="data-table-content" ref={ref}>
        {numberItem.data.map((item, index) => (
          <ItemTable
            key={index}
            measure={item[lang].title.data}
            content={item[lang].contenu.data}
          />
        ))}
      </div>
    </section>
  );
});
