import { forwardRef, useContext, useEffect, useRef, useState } from "react";
import "./latestNews.scss";
import { ButtonPlus } from "../../components/ButtonPlus/ButtonPlus";
import { StoreContext } from "../../store/store";

export const LatestNews = forwardRef((props, ref) => {
  const { section, template, bgColor } = props;
  const { title, listNews } = section;
  const { lang } = useContext(StoreContext);

  const scrollRef = useRef(null);
  const containerRef = useRef(null);
  const [isMobile, setIsMobile] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth <= 960);
    };

    checkIfMobile();
    window.addEventListener("resize", checkIfMobile);

    return () => window.removeEventListener("resize", checkIfMobile);
  }, []);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? listNews.data.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === listNews.data.length - 1 ? 0 : prevIndex + 1
    );
  };

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.style.transform = `translateX(-${
        currentIndex * 100
      }%)`;
    }
  }, [currentIndex, isMobile]);

  return (
    <section className="latest-news-section">
      <span className="latest-news-label">{title.data}</span>

      <div ref={scrollRef} className="latest-news-container">
        <div ref={containerRef} className="latest-news-wrapper">
          {listNews.data.map((news, index) => (
            <div className="latest-news-content-container" key={index}>
              <div
                className={`latest-news-content-container-wrapper ${
                  index % 2 === 0 ? "order-first" : "order-last"
                }`}
              >
                <div
                  className="latest-news-content-container-left-scroll"
                  onClick={handlePrev}
                >
                  <img src="/icon/chevron-left.svg" alt="chevron-left" />
                </div>
                <div
                  className={`latest-news-content ${
                    index % 2 === 0 ? "bg-beige" : "bg-grey"
                  }`}
                >
                  <div className="latest-news-content-upper">
                    <span
                      className={`latest-news-content-title ${
                        index % 2 === 0 ? "" : "light"
                      }`}
                    >
                      {news[lang].title.data}
                    </span>
                    <span
                      className={`latest-news-content-subtitle ${
                        index % 2 === 0 ? "" : "light"
                      }`}
                    >
                      {news[lang].subTitle.data}
                    </span>
                  </div>

                  <div
                    className={`latest-news-content-description ${
                      index % 2 === 0 ? "" : "light"
                    }`}
                    dangerouslySetInnerHTML={{
                      __html: news[lang].content.data,
                    }}
                  />

                  <ButtonPlus
                    color={index % 2 === 0 ? "dark" : "light"}
                    to={news[lang].btnUrl.data}
                  />

                  {/* <button
                className={`button ${index % 2 === 0 ? "" : "light"}`}
                onClick={news.action}
              >
                {news.actionLabel}
              </button> */}
                </div>
                <div
                  className="latest-news-content-container-right-scroll"
                  onClick={handleNext}
                >
                  <img src="/icon/chevron-right.svg" alt="chevron-right" />
                </div>
              </div>

              <div className="latest-news-content-image-container">
                <img
                  src={news[lang].media.data.path}
                  alt={news[lang].media.data.alt}
                  className={`latest-news-content-image ${
                    index % 2 === 0 ? "order-last" : "order-first"
                  }`}
                />
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="latest-news-bullets">
        {listNews.data.map((_, index) => (
          <div
            key={index}
            className={`latest-news-bullet ${
              index === currentIndex ? "active" : ""
            }`}
            onClick={() => setCurrentIndex(index)}
          />
        ))}
      </div>
    </section>
  );
});
